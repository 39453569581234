import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Search as SearchIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
} from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./ReadyToPublish.styles";

interface IProps {
  published: boolean;
  checkPassed: boolean;
  handlePublish: () => void;
  publishedThingText: string;
  skipCheck?: boolean;
}

export const ReadyToPublish: FC<IProps> = (props) => {
  const {
    published,
    checkPassed,
    handlePublish,
    publishedThingText,
    skipCheck,
  } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.firstDiv}>
        {!skipCheck ? (
          <>
            {published ? (
              <CheckCircleOutlineIcon fontSize="large" color="primary" />
            ) : checkPassed ? (
              <ThumbUpOutlinedIcon fontSize="large" color="primary" />
            ) : (
              <SearchIcon fontSize="large" color="primary" />
            )}

            <Typography color="textSecondary" className={classes.text}>
              {published
                ? `${publishedThingText} is published`
                : checkPassed
                ? `${publishedThingText} is ready to be published`
                : `Check if ${publishedThingText} is ready to be published`}
            </Typography>
          </>
        ) : null}
      </div>
      <Button
        onClick={handlePublish}
        variant={published ? "contained" : "outlined"}
      >
        {published
          ? `Unpublish ${publishedThingText}`
          : checkPassed
          ? `Publish ${publishedThingText}`
          : "Check status"}
      </Button>
    </div>
  );
};
