import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Title",
      name: "title",
      placeholder: "Title of the notification",
      required: true,
    },
    localise: true,
    mainHelperText:
      "This is visible inside the notification section of the app.",
  },
  body: {
    localise: true,
    inputProps: {
      label: "Message",
      name: "message",
      placeholder: "Message...",
      required: true,
    },
    mainHelperText:
      "This is visible inside the notification section of the app.",
  },
  links: {
    localise: true,
    inputProps: {
      label: "Links",
      name: "links",
      placeholder: "Title of link|https://google.com",
    },
    mainHelperText:
      "This is visible inside the notification section of the app. You can add multiple links with a comma.",
  },
  titlePush: {
    inputProps: {
      name: "titlePush",
      label: "Push title",
      placeholder: "...",
    },
    localise: true,
    mainHelperText:
      "Title of the push notification. Cannot be changed once sent!",
  },
  subtitlePush: {
    localise: true,
    inputProps: {
      label: "Push subtitle",
      name: "subtitlePush",
      placeholder: "",
    },
    mainHelperText:
      "Subtitle of the push notification. Cannot be changed once sent!",
  },
  bodyPush: {
    inputProps: {
      label: "Push message",
      name: "messagePush",
      placeholder: "Message...",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Message of the push notification. Cannot be changed once sent!",
  },

  gallery: {
    inputProps: {
      label: "Gallery",
      name: "gallery",
    },
    rule: "media",
    required: true,
    localise: true,
    mainLocaleRequiredOnly: true,
  },
});
